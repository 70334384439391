const whitelist = {
    "Nuggets": {
        address: "0xb9eE65be6b413fcB711ced63cCA8EFFB3AE445e7",
        image: require("../assets/IMG/nuggets.png"),
        coverImage: require("../assets/IMG/nugget_cover1.jpg"),
        description: `Nuggets are 10000 of the hottest Fusion of Cackles and Mutants on the  BitTorrent each having it's own unique properties.`,
        website: "https://nuggets.cfd/",
        // twitter: "https://x.com/",
        // telegram: "https://t.me/",
        // discord: "https://discord.com/",
        // youtube: "https://youtube.com/"
    },
    "BitTorrent Web3 Domain": {
        address: "0xA1019535E6b364523949EaF45F4B17521c1cb074",
        image: require("../assets/IMG/trx.png"),
        coverImage: require("../assets/IMG/btt_cover.png"),
        description: `BitTorrent Web3 Domains. Your Perfect Multi-chain Identity.`,
        website: "https://trxdomains.xyz/bttc",
        twitter: "https://twitter.com/TrxDomains"
    },
    "Ohayo": {
        address: "0xA27A2A0F6A60574A6084A9a1A894c173237cED24",
        image: require("../assets/IMG/ohayo.png"),
        coverImage: require("../assets/IMG/cover.png"),
        description: `This is a collection with "Turkis" as Background.`
    },
};

export default whitelist;
